import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { registerGoogleEvent } from '../../shared/utils';
import * as classes from './Hero.module.scss';

const Hero = props => {
    const { title, description, button2Label, button2Link, paddingBottom = 100 } = props;
    const location = useLocation();
    const [ primaryAction, setPrimaryAction ] = useState(<a href={`/app/auth/register?from=${location.pathname}`}
                                                            className="btn btn-primary btn-wide"
                                                            onClick={() => handleCallToActionClick('primary')}
                                                            id="hero-primary"
    >
        <Trans>Get started</Trans>
    </a>);
    const posthog = usePostHog();

    useEffect(() => {
        if (typeof window !== 'undefined' && window.posthog) {
            posthog.onFeatureFlags(() => {
                if (posthog.getFeatureFlag('pricing-vs-register') === 'pricing') {
                    setPrimaryAction(<Link to="/pricing" className="btn btn-primary btn-wide" id="is-hero-primary">
                        <Trans>Get started</Trans>
                    </Link>);
                }
            });
        }
    }, []);

    const handleCallToActionClick = source => {
        const label = `${source}_${location.pathname}`;
        registerGoogleEvent('click_callToAction', label);
        if (source === 'primary') {
            registerGoogleEvent('sign_up_intent');
        }

        return true;
    };

    const { language } = useI18next();
    const data = useStaticQuery(graphql`
      query {
        allFile(filter: { relativePath: { regex: "/aida-hero-document-fields-relations-/" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(width: 821, height: 940, placeholder: BLURRED)
            }
            relativePath
          }
        }
      }
    `);

    const imagePath = `hero/aida-hero-document-fields-relations-${language}.png`;
    const imageNode = data.allFile.nodes.find((node) => node.relativePath === imagePath);
    const image = getImage(imageNode);

    return <div className={classes.Hero}>
        <div className={clsx(classes.Overlay, 'd-xl-none')} />
        <div className={clsx(classes.BackgroundImage, 'd-xl-none')} />
        <div className="container p-0" style={{ display: 'flex' }}>
            <div className={classes.Content}>
                <h1 className={classes.Title}>
                    {title}
                </h1>
                <h2 className={classes.Description}>
                    {description}
                </h2>
                <div className={classes.Buttons} style={{ paddingBottom: `${paddingBottom}px` }}>
                    {primaryAction}
                    <Link to={button2Link || '/platform/data-extraction'} className="btn btn-secondary btn-wide gray"
                          id="hero-secondary"
                          onClick={() => handleCallToActionClick('secondary')}>
                        {button2Label ? button2Label : <Trans>Explore the features</Trans>}
                    </Link>
                </div>
            </div>
            <div className={clsx(classes.Image, 'd-none', 'd-xl-block')}>
                <GatsbyImage
                    image={image}
                    alt="AIDA geometric pattern"
                />
            </div>
        </div>
    </div>;
};

Hero.propTypes = {
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
    description: PropTypes.object.isRequired,
    button2Label: PropTypes.object,
    button2Link: PropTypes.string,
    paddingBottom: PropTypes.number
};

export default Hero;
