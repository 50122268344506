import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// noinspection RequiredAttributes
import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import AnimatedNumber from '../components/AnimatedNumber/AnimatedNumber';
import Hero from '../components/Hero/Hero';
import { Seo } from '../components/Seo';
import { OutboundLink } from '../components/UI/OutboundLink/OutboundLink';
import documentTypesIcon from '../images/home/aida-document-types.svg';
import aidaGptIcon from '../images/home/aida-features-aida-gpt.png';

import dataExtractionIcon from '../images/home/aida-features-data-extraction.svg';
import documentManagementIcon from '../images/home/aida-features-document-management.svg';
import relationsIcon from '../images/home/aida-features-relations.svg';
import tasksIcon from '../images/home/aida-features-tasks.svg';
import hybridAiIcon from '../images/home/aida-hybrid-ai-engine.svg';
import productivityGraph from '../images/home/aida-productivity-graph.svg';
import productivityIcon from '../images/home/aida-productivity.svg';
import propertiesIcon from '../images/home/aida-property-configurations.svg';
import roiMoney from '../images/home/aida-roi-money.svg';
import roiIcon from '../images/home/aida-roi.svg';
import timeConfigurationIcon from '../images/home/aida-time-configuration.svg';
import timeSavedIcon from '../images/home/aida-time-saved-clock.svg';
import timeSavedHourglass from '../images/home/aida-time-saved-hourglass.svg';
import Layout from '../layouts/layout';

import * as classes from './index.module.scss';

const IndexPage = props => {
    const { t, language } = useI18next();

    const videoUrl = language === 'it' ? 'https://www.youtube.com/embed/q6p5ai9n818' : 'https://www.youtube.com/embed/DzmFZR4WqZA';

    return <Layout location={props.location}>
        <Hero
            title={t('index-page:title')}
            description={<Trans i18nKey="index-page:mainDescription">
                <p>AIDA is the industry-leading solution for <strong>intelligent document processing</strong>.</p>
                <p>Making document management effortless and affordable, AIDA revolutionizes how you interact with your
                   documents.</p>
                <p>Leveraging a user-friendly point-and-click interface, AIDA learns to extract <strong>any
                                                                                                        field</strong> from <strong>any
                                                                                                                                    document</strong> after
                   seeing just <strong>one example</strong>.<br />
                   Enables you to automate archiving, task management, and Knowledge Graph creation to reveal <strong>valuable
                                                                                                                      connections</strong> between
                   documents and drive your organization's efficiency and insights.</p>
                <p>Step into the future of document processing with AIDA.</p>
            </Trans>}
            button2Label={<Trans>Discover the platform</Trans>}
            button2Link="/platform/data-extraction"
        />
        <div className={clsx(classes.Infographic, 'container p-0')}>
            <div className={classes.Top}>
                <Link className={classes.Item} to="/platform/data-extraction">
                    <div className={classes.Icon}>
                        <img src={dataExtractionIcon} alt="AIDA Data Extraction" height="90" />
                    </div>
                    <div className={classes.Title}>
                        <Trans i18nKey="index-page:features.data_extraction.title">Data extraction</Trans>
                    </div>
                    <Trans i18nKey="index-page:features.data_extraction.description">
                        <span>AIDA learns to extract data from <strong>any type of document</strong>. It's able to recognize and handle
                        never-seen-before documents, even highly unstructured ones, thanks to its advanced AI engine.
                              The learning is active from the <strong>first document</strong> onwards.</span>
                    </Trans>
                </Link>
            </div>
            <div className={classes.Middle}>
                <div className={classes.Line} />
                <Link className={clsx(classes.Item, classes.Left)} to="/platform/relations-knowledge-graph">
                    <div className={classes.LineVertical} />
                    <div className={classes.Icon}>
                        <img src={relationsIcon} alt="AIDA relations between documents" height="90" />
                    </div>
                    <div className={classes.Title}>
                        <Trans i18nKey="index-page:features.relations.title">Document Relationships</Trans>
                    </div>
                    <Trans i18nKey="index-page:features.relations.description">
                        <span>AIDA manages and reveals <strong>valuable connections between documents</strong>, providing a deeper insight into
                        your data. This can help you identify patterns, better understand your operations, and make more
                              informed decisions.</span>
                    </Trans>
                </Link>
                <Link className={classes.Item} to="/platform/archive-document-management">
                    <div className={classes.LineVertical} />
                    <div className={classes.Icon}>
                        <img src={documentManagementIcon} alt="AIDA Document Management" height="90" />
                    </div>
                    <div className={classes.Title}>
                        <Trans i18nKey="index-page:features.document_management.title">Document Management</Trans>
                    </div>
                    <Trans i18nKey="index-page:features.document_management.description">
                        <span>AIDA not only extracts data but also manages the <strong>efficient and unlimited archiving</strong> of documents.
                              This means that you can keep all your important documents in one place, safe and easy to use.</span>
                    </Trans>
                </Link>
                <Link className={clsx(classes.Item, classes.Right)} to="/platform/data-extraction">
                    <div className={classes.LineVertical} />
                    <div className={classes.Icon}>
                        <img src={tasksIcon} alt="AIDA document tasks" height="90" />
                    </div>
                    <div className={classes.Title}>
                        <Trans i18nKey="index-page:features.tasks.title">Document Tasks</Trans>
                    </div>
                    <Trans i18nKey="index-page:features.tasks.description">
                        <span>AIDA simplifies document activity management, <strong>automating processes</strong> that would otherwise require
                        significant investment in time and resources. From simple modifications to complex workflows,
                              AIDA keeps everything under control.</span>
                    </Trans>
                </Link>
            </div>
            <div className={classes.Bottom}>
                <div className={classes.LineVertical} />
                <Link className={classes.Item} to="/platform/aida-gpt">
                    <div className={classes.Icon}>
                        <img src={aidaGptIcon} alt="AIDA-GPT integration" height="90" />
                    </div>
                    <div className={classes.Title}>
                        <Trans i18nKey="index-page:features.aida_gpt.title">AIDA-GPT</Trans>
                    </div>
                    <Trans i18nKey="index-page:features.aida_gpt.description">
                        <p>AIDA-GPT is the <strong>generative AI</strong> component of AIDA based on LLM technologies
                           like OpenAI's ChatGPT that elevates document tasks by utilizing extracted data, full content,
                           and inter-document links.</p>
                        <p>Interact with documents through <strong>natural conversations</strong>, obtaining instant,
                           informed responses to improve efficiency and insights.</p>
                    </Trans>
                </Link>
            </div>
        </div>
        <div className={classes.Solutions}>
            <div className="container p-0">
                <h2 className={clsx(classes.Title, 'h2')}>
                    <Trans>Thousands of solutions created</Trans>
                </h2>
                <div className="subtitle" style={{ marginBottom: '60px' }}>
                    <Trans>by our users so far</Trans>
                </div>
                <div className="row gx-0 gx-lg-5">
                    <div className="col-lg-6">
                        <div className={clsx(classes.Detail, classes.Primary)}>
                            <div className={classes.Title}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className={classes.Image}>
                                        <img
                                            src={documentTypesIcon}
                                            alt="AIDA document types"
                                            width={150}
                                            height={100}
                                        />
                                    </div>
                                    <div>
                                        <div className={classes.Primary}>
                                            <AnimatedNumber id="counter-documentTypes" targetNumber={5500}
                                                            className="text-gradient" suffix="+" />
                                        </div>
                                        <Trans>document types</Trans>
                                    </div>
                                </div>
                            </div>
                            <Trans i18nKey="index-page:detail1">
                                A document type is simply a configuration for a set of fields that you want to be
                                extracted
                                from
                                a document.
                                It is not related to the document layout, source or format, so you can process multiple
                                documents with a single configuration.
                            </Trans>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={clsx(classes.Detail, classes.Primary)}>
                            <div className={classes.Title}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className={classes.Image}>
                                        <img
                                            src={propertiesIcon}
                                            alt="AIDA properties - extracted fields"
                                            width={150}
                                            height={100}
                                        />
                                    </div>
                                    <div>
                                        <div className={classes.Primary}>
                                            <AnimatedNumber id="counter-properties" targetNumber={41000}
                                                            className="text-gradient" suffix="+" />
                                        </div>
                                        <Trans>properties</Trans>
                                    </div>
                                </div>
                            </div>
                            <Trans i18nKey="index-page:detail2">
                                A property is a configuration for a field that can be trained to be extracted from
                                unlimited
                                documents.
                                No templating and no batches of training documents are needed: all you need to do is
                                click
                                on
                                the value on the document, and AIDA will learn from it, after only one document.
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx(classes.Last, 'row gx-0 gx-lg-5')}>
                    <div className="col-lg-6">
                        <div className={classes.Detail}>
                            <div className={classes.Icon}>
                                <img src={timeConfigurationIcon} alt="AIDA eases configuration" height="90" />
                            </div>
                            <Trans i18nKey="index-page:detail3">
                                Configuration in AIDA is straightforward and no-code, and you don't need any special
                                knowledge.
                                You will be guided into creating your configuration for any document, to be productive
                                and
                                start
                                saving time from the first hour of use.
                            </Trans>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={classes.Detail}>
                            <div className={clsx(classes.Icon, classes.Tight)}>
                                <img src={hybridAiIcon} alt="AIDA saves time" height="90" />
                            </div>
                            <Trans i18nKey="index-page:detail4">
                                AIDA works with any document, because it doesn't make any assumption on them.
                                Its Hybrid-AI engine enables field extraction to be taught with just a single document.
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={clsx(classes.Success, 'container p-0')}>
            <h2 className={clsx(classes.Title, 'h2')}>
                <Trans>Verified success</Trans>
            </h2>
            <div className="subtitle" style={{ marginBottom: '60px' }}>
                <Trans>from real statistics</Trans>
            </div>
            <div className="row gx-0 gx-lg-5">
                <div className="col-lg-4">
                    <div className={classes.Detail}>
                        <div className={classes.Image}>
                            <img src={timeSavedHourglass} alt="AIDA saves time (image)" width="100%" />
                            <div className={classes.Icon}>
                                <img src={timeSavedIcon} alt="AIDA saves time (icon)" width={60} height={60} />
                            </div>
                        </div>
                        <div className={clsx(classes.Statistic, 'text-gradient')}>
                            <AnimatedNumber id="counter-time" targetNumber={96} className="text-gradient" suffix="%" />
                        </div>
                        <h2 className={classes.Title}><Trans>Time saved</Trans></h2>
                        <div className={classes.Description}>{t('index-page:stat2')}</div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className={classes.Detail}>
                        <div className={classes.Image}>
                            <img src={productivityGraph} alt="AIDA increases productivity (image)" width="100%" />
                            <div className={classes.Icon}>
                                <img src={productivityIcon} alt="AIDA increases productivity (icon)" width={60}
                                     height={60} />
                            </div>
                        </div>
                        <div className={clsx(classes.Statistic, 'text-gradient')}>
                            <AnimatedNumber id="counter-boost" targetNumber={21} className="text-gradient" suffix="x" />
                        </div>
                        <h2 className={classes.Title}><Trans>Productivity boost</Trans></h2>
                        <div className={classes.Description}>{t('index-page:stat1')}</div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className={clsx(classes.Detail, classes.Last)}>
                        <div className={classes.Image}>
                            <img src={roiMoney} alt="AIDA returns its investment (image)" width="100%" />
                            <div className={classes.Icon}>
                                <img src={roiIcon} alt="AIDA returns its investment (icon)" width={60}
                                     height={60} />
                            </div>
                        </div>
                        <div className={clsx(classes.Statistic, 'text-gradient')}>
                            <AnimatedNumber id="counter-roi" targetNumber={1500} className="text-gradient" suffix="%" />
                        </div>
                        <h2 className={classes.Title}><Trans>ROI</Trans></h2>
                        <div className={classes.Description}>
                            <Trans i18nKey="index-page:stat3">
                                <div>Obtaining high Return on Investment is natural with AIDA: this is the average
                                     that its users have reached after 3 months of use, with guidance from our <Link
                                        to="/support/contacts">Onboarding team</Link>.
                                </div>
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={classes.VideoOuterContainer}>
            <div className={clsx(classes.VideoContainer, 'container', 'p-0')}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-lg-7', classes.Video)}>
                        <div className="youtube-container">
                            <iframe
                                width="auto"
                                height="auto"
                                src={videoUrl}
                                title="Welcome to AIDA"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen=""
                            />
                        </div>
                    </div>
                    <div className={clsx('col-lg-5', classes.Container)}>
                        <div className={classes.Content}>
                            <Trans i18nKey="index-page:video">
                                <p>AIDA is your business's <strong>intelligent solution for document management</strong>.
                                   From invoices and contracts to essential correspondence, AIDA digitizes and extracts
                                   valuable data, integrating <strong>seamlessly</strong> into your systems.</p>
                                <p>With <strong>no need for technical knowledge</strong>, AIDA's user-friendly interface
                                   enhances efficiency within your organization.
                                   Tailored for enterprise users, AIDA streamlines operations, automating tasks, and
                                   revealing insights.</p>
                                <p>Embrace the <strong>future of document management</strong> without coding. Let AIDA,
                                   your intelligent business assistant, elevate your organization today.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={classes.Customers}>
            <div className="container p-0">
                <h2 className={clsx(classes.Title, 'h2')}>
                    <Trans>Customers</Trans>
                </h2>
                <h3 className="subtitle">
                    <Trans>that grow with us</Trans>
                </h3>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-jacobacci.png" alt="Jacobacci & Partners" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-dierre.png" alt="Dierre porte" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-sauter.png" alt="Sauter" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-lascala.png" alt="LaScala" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-gvascensori.png" alt="GV Ascensori" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-farsley.png" alt="Farsley Transport LTD" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-camparigroup.png" alt="Campari Group" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-ischebeck-titan.png"
                                     alt="Ischebeck Titan" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-afinnaone.png" alt="Afinna One" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-denora.png" alt="De Nora" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-arago.png" alt="Laboratorio Aragó" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-briggs.png" alt="Briggs Equipment" />
                    </div>
                    <div
                        className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-arval.png" alt="Arval" />
                    </div>
                    <div className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-unifarma.png" alt="Unifarma" />
                    </div>
                    <div
                        className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-crai.png" alt="Codè-Crai" />
                    </div>
                    <div
                        className={clsx(classes.Logo, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-fratelli-beretta.png"
                                     alt="Fratelli Beretta" />
                    </div>
                    <div
                        className={clsx(classes.Logo, classes.LastLeft, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-hitachi.png" alt="Hitachi Rail" />
                    </div>
                    <div
                        className={clsx(classes.Logo, classes.LastRight, 'col-sm-6', 'col-md-4', 'col-lg-3', 'col-xxl-2')}>
                        <StaticImage src="../images/customers/aida-customer-morson.png" alt="Morson Project" />
                    </div>
                </div>
            </div>
        </div>
        <div className={classes.AwardsOuterContainer}>
            <div className={clsx('container', 'p-0')}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-lg-4', classes.Award)}>
                        <OutboundLink href="https://www.deep-analysis.net/innovation-award-winners-2023"
                                      target="_blank"
                                      rel="noopener">
                            <StaticImage src="../images/awards/aida-deep-analysis-2023-innovation-award.png"
                                         alt="Deep Analysis 2023 Innovation Award" />
                        </OutboundLink>
                    </div>
                    <div className={clsx('col-lg-8', classes.Container)}>
                        <FontAwesomeIcon icon="quote-left" />
                        <div className={classes.Content}>
                            <Trans i18nKey="index-page:award.1">
                                <p><strong>TCLab’s AIDA</strong> document capture product contains innovative features
                                                                 that set it apart, including its hybrid approach
                                                                 combining AI with no-code templating.</p>
                                <p>We particularly liked its pragmatic, hybrid approach to
                                   capture and automation that does not rely solely on AI
                                   but combines the best of both worlds: templating
                                   (albeit simple, no code) and AI.</p>
                                <p>The result is a system that can be deployed quickly and can handle a wide array of
                                   document types with minimal effort. </p>
                            </Trans>
                        </div>
                        <div className={classes.Signature}>
                            <OutboundLink href="https://www.deep-analysis.net/innovation-award-winners-2023"
                                          target="_blank"
                                          rel="noopener">Deep Analysis</OutboundLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "index-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
    <Seo />
);

