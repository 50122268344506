// extracted by mini-css-extract-plugin
export var Award = "index-module--Award--cbbaf";
export var AwardsOuterContainer = "index-module--AwardsOuterContainer--a8d16";
export var Bottom = "index-module--Bottom--4e48d";
export var Container = "index-module--Container--9c1dd";
export var Content = "index-module--Content--91715";
export var Customers = "index-module--Customers--42cdc";
export var Description = "index-module--Description--50a8f";
export var Detail = "index-module--Detail--06ce4";
export var Icon = "index-module--Icon--a1205";
export var Image = "index-module--Image--5d3c5";
export var Infographic = "index-module--Infographic--9ff52";
export var Item = "index-module--Item--c5588";
export var Last = "index-module--Last--d48ff";
export var LastLeft = "index-module--LastLeft--c408e";
export var LastRight = "index-module--LastRight--9647c";
export var Left = "index-module--Left--35fcb";
export var Line = "index-module--Line--63a0c";
export var LineVertical = "index-module--LineVertical--1d138";
export var Logo = "index-module--Logo--5bd86";
export var Middle = "index-module--Middle--33d9a";
export var Primary = "index-module--Primary--43448";
export var Right = "index-module--Right--0d3ca";
export var Row = "index-module--Row--7df5d";
export var Signature = "index-module--Signature--1ab67";
export var Solutions = "index-module--Solutions--6f696";
export var Statistic = "index-module--Statistic--73198";
export var Success = "index-module--Success--97d75";
export var Tight = "index-module--Tight--a1a6e";
export var Title = "index-module--Title--f80a5";
export var Top = "index-module--Top--6f4a0";
export var Video = "index-module--Video--82957";
export var VideoContainer = "index-module--VideoContainer--f7df9";
export var VideoOuterContainer = "index-module--VideoOuterContainer--12b5c";
export var aida = "index-module--aida--f641d";
export var store = "index-module--store--b388f";